import { SycrakeaBossToolColors } from "bdo/atoraxxion/sycrakea/boss-tool/colors";
import { SycrakeaBossToolCountdown } from "bdo/atoraxxion/sycrakea/boss-tool/countdown";
import { cssName } from "components";
import "./index.css";

SycrakeaBossTool.displayName = "SycrakeaBossTool";
export function SycrakeaBossTool(): React.ReactElement {
    return (
        <div className={cssName(SycrakeaBossTool)}>
            <SycrakeaBossToolCountdown />
            <SycrakeaBossToolColors />
        </div>
    );
}
