import { Orzekea } from "bdo/atoraxxion/orzekea";
import { Sycrakea } from "bdo/atoraxxion/sycrakea";
import { Vahmalkea } from "bdo/atoraxxion/vahmalkea";
import { Yolunakea } from "bdo/atoraxxion/yolunakea";
import { useEffect } from "react";
import { setDocumentTitle } from "util/browser";
import { IndexedRoutes, RouteInfo } from "util/route";
import "./index.css";

import idk from "assets/svg/idk.svg";
import idk2 from "assets/svg/idk2.svg";
import idk3 from "assets/svg/idk3.svg";
import idk4 from "assets/svg/idk4.svg";
import { cssName } from "components";
import { NavLink } from "react-router-dom";

type AtoraxxionPageNavRouteInfo = RouteInfo & Readonly<{
    key: string;
    name: string;
    iconSrc: string;
    isEnabled?: boolean;
}>;

const NAV_ROUTES: ReadonlyArray<AtoraxxionPageNavRouteInfo> = [
    {
        key: "vahmalkea",
        path: "vahmalkea",
        element: <Vahmalkea />,
        name: "Vahmalkea",
        iconSrc: idk,
    },
    {
        key: "sycrakea",
        path: "sycrakea",
        element: <Sycrakea />,
        name: "Sycrakea",
        iconSrc: idk2,
        isEnabled: true,
    },
    {
        key: "yolunakea",
        path: "yolunakea",
        element: <Yolunakea />,
        name: "Yolunakea",
        iconSrc: idk3,
    },
    {
        key: "orzekea",
        path: "orzekea",
        element: <Orzekea />,
        name: "Orzekea",
        iconSrc: idk4,
    },
];

AtoraxxionPageNavigationEntry.displayName = "AtoraxxionPageNavigationEntry";
function AtoraxxionPageNavigationEntry(props: Readonly<{
    routeInfo: AtoraxxionPageNavRouteInfo;
}>): React.ReactElement {
    const route = props.routeInfo;
    return (
        <NavLink to={route.path}
            className={({isActive}) => cssName(AtoraxxionPageNavigationEntry, [
                [],
                ...route.isEnabled ? [] : [["disabled"]],
                ...isActive ? [["active"]] : [],
                [route.key],
            ])}
        >
            <img className={cssName(AtoraxxionPageNavigationEntry, [
                ["symbol"],
            ])} src={props.routeInfo.iconSrc} alt={props.routeInfo.key} />
            <span className={cssName(AtoraxxionPageNavigationEntry, [
                ["text"],
            ])}>{props.routeInfo.name}</span>
        </NavLink>
    );
}

AtoraxxionPageNavigationBar.displayName = "AtoraxxionPageNavigationBar";
function AtoraxxionPageNavigationBar(props: {
    routes: ReadonlyArray<AtoraxxionPageNavRouteInfo>;
}): React.ReactElement {
    return (
        <div className={cssName(AtoraxxionPageNavigationBar)}>
            <div className={cssName(AtoraxxionPageNavigationBar, [
                ["entries"],
            ])}>
                {
                    props.routes.map((route, index) => {
                        return (
                            <AtoraxxionPageNavigationEntry
                                key={index}
                                routeInfo={route}
                            />
                        );
                    })
                }
            </div>
        </div>
    );
}

AtoraxxionIndex.displayName = "AtoraxxionIndex";
function AtoraxxionIndex(): React.ReactElement {
    return (
        <></>
    );
}

AtoraxxionPage.displayName = "AtoraxxionPage";
export function AtoraxxionPage(): React.ReactElement {
    useEffect(() => {
        setDocumentTitle("Atoraxxion");
    }, []);

    return (
        <div className={cssName(AtoraxxionPage)}>
            <AtoraxxionPageNavigationBar
                routes={NAV_ROUTES}
            />
            <div className={cssName(AtoraxxionPage, [
                ["content"],
            ])}>
                <IndexedRoutes
                    indexElement={<AtoraxxionIndex />}
                    routes={NAV_ROUTES}
                />
            </div>
        </div>
    );
}
