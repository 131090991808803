import { ReactComponent as LeftArrow } from "assets/svg/chevron-left.svg";
import { ReactComponent as RightArrow } from "assets/svg/chevron-right.svg";
import { cssName } from "components";
import { Button, TextButton } from "components/button";
import { useState } from "react";
import { Elixir, ElixirRecord, sortElixirs } from "util/elixir";
import { assertNever } from "util/typecheck";
import "./index.css";

export const DEFAULT_ELIXIRS: ReadonlyArray<Elixir> = [
    ElixirRecord.frenzy,
    ElixirRecord.draining,
    ElixirRecord.perforation,
    ElixirRecord.death,
    ElixirRecord.assassination,
    ElixirRecord.detection,
    ElixirRecord.will,
    ElixirRecord.life,
    ElixirRecord.grim_reaper,
    ElixirRecord.defense,
    ElixirRecord.destruction,
];

export type ElixirType = "green" | "blue";

export type ElixirPoolOptions = Readonly<{
    elixirType: ElixirType;
    isCombatOnly: boolean;
}>;

export const DEFAULT_ELIXIR_POOL_OPTIONS: ElixirPoolOptions = {
    elixirType: "blue",
    isCombatOnly: true,
};

ElixirLotteryElixirsElixirField.displayName = "ElixirLotteryElixirsElixirField";
export function ElixirLotteryElixirsElixirField(props: Readonly<{
    elixir: Elixir;
    elixirType: ElixirType;
}>): React.ReactElement {
    const getElixirIconSrc = (): string => {
        switch (props.elixirType) {
            case "blue":
                return props.elixir.blueIcon;
            case "green":
                return props.elixir.greenIcon;
            default:
                assertNever(props.elixirType);
        }
    };

    const getElixirName = (): string => {
        switch (props.elixirType) {
            case "blue":
                return props.elixir.blue;
            case "green":
                return props.elixir.green;
            default:
                assertNever(props.elixirType);
        }
    };

    return (
        <div className={cssName(ElixirLotteryElixirsElixirField, [
            [],
            [props.elixirType],
        ])}>
            <img className={cssName(ElixirLotteryElixirsElixirField, [
                ["icon"],
            ])} src={getElixirIconSrc()} alt={props.elixir.key} />
            <span className={cssName(ElixirLotteryElixirsElixirField, [
                ["name"],
            ])}>
                {getElixirName()}
            </span>
        </div>
    );
}

ElixirLotteryElixirsElixirEditingField.displayName = "ElixirLotteryElixirsElixirEditingField";
function ElixirLotteryElixirsElixirEditingField(props: Readonly<{
    elixir: Elixir;
    elixirType: ElixirType;
    isInPool: boolean;
    onEditClick: () => void;
}>): React.ReactElement {
    const getElixirIconSrc = (): string => {
        switch (props.elixirType) {
            case "blue":
                return props.elixir.blueIcon;
            case "green":
                return props.elixir.greenIcon;
            default:
                assertNever(props.elixirType);
        }
    };

    const getElixirName = (): string => {
        switch (props.elixirType) {
            case "blue":
                return props.elixir.blue;
            case "green":
                return props.elixir.green;
            default:
                assertNever(props.elixirType);
        }
    };

    return (
        <div className={cssName(ElixirLotteryElixirsElixirEditingField, [
            [],
            [props.elixirType],
        ])}>
            {
                props.isInPool
                ? null
                : <LeftArrow
                    className={cssName(ElixirLotteryElixirsElixirEditingField, [
                        ["arrow"],
                        ["arrow", "green"],
                    ])}
                    width={30} height={30}
                    onClick={props.onEditClick}
                />
            }
            <img className={cssName(ElixirLotteryElixirsElixirEditingField, [
                ["icon"],
            ])} src={getElixirIconSrc()} alt={props.elixir.key} />
            <span className={cssName(ElixirLotteryElixirsElixirEditingField, [
                ["name"],
            ])}>
                {getElixirName()}
            </span>
            {
                props.isInPool
                ? <RightArrow
                    className={cssName(ElixirLotteryElixirsElixirEditingField, [
                        ["arrow"],
                        ["arrow", "red"],
                    ])}
                    width={30} height={30}
                    onClick={props.onEditClick}
                />
                : null
            }
        </div>
    );
}

ElixirLotteryElixirs.displayName = "ElixirLotteryElixirs";
export function ElixirLotteryElixirs(props: Readonly<{
    elixirPool: ReadonlyArray<Elixir>;
    setElixirPool: (elixirPool: ReadonlyArray<Elixir>) => void;
    elixirPoolOptions: ElixirPoolOptions;
    setElixirPoolOptions: (poolOptions: ElixirPoolOptions) => void;
}>): React.ReactElement {
    const {
        elixirType,
        isCombatOnly
    } = props.elixirPoolOptions;

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [selectedElixirs, setSelectedElixirs] = useState<ReadonlyArray<Elixir>>(sortElixirs(props.elixirPool));

    return (
        <div className={cssName(ElixirLotteryElixirs)}>
            <div className={cssName(ElixirLotteryElixirs, [
                ["elixirPool"],
            ])}>
                <div className={cssName(ElixirLotteryElixirs, [
                    ["elixirPool", "controls"],
                ])}>
                    <TextButton
                        text={isEditing ? "Save" : "Change Elixir Pool"}
                        onClick={() => {
                            if (isEditing) {
                                props.setElixirPool(selectedElixirs);
                            }
                            setIsEditing(!isEditing);
                        }}
                    />
                    {
                        isEditing
                        ? <TextButton
                            text={"Use Default List"}
                            onClick={() => {
                                setSelectedElixirs(DEFAULT_ELIXIRS);
                            }}
                        />
                        : null
                    }
                </div>
                <div className={cssName(ElixirLotteryElixirs, [
                    ["elixirPool", "list"],
                ])}>
                    {
                        sortElixirs(selectedElixirs).map((elixir) => {
                            if (isEditing) {
                                return (
                                    <ElixirLotteryElixirsElixirEditingField
                                        key={elixir.key}
                                        elixir={elixir}
                                        elixirType={elixirType}
                                        isInPool={true}
                                        onEditClick={() => {
                                            setSelectedElixirs(selectedElixirs.filter((selectedElixir) => {
                                                return selectedElixir.key !== elixir.key;
                                            }));
                                        }}
                                    />
                                );
                            } else {
                                return (
                                    <ElixirLotteryElixirsElixirField
                                        key={elixir.key}
                                        elixir={elixir}
                                        elixirType={elixirType}
                                    />
                                );
                            }
                        })
                    }
                </div>
            </div>
            {
                isEditing
                ? <div className={cssName(ElixirLotteryElixirs, [
                    ["elixirReserve"],
                ])}>
                    <div className={cssName(ElixirLotteryElixirs, [
                        ["elixirReserve", "controls"],
                    ])}>
                        <TextButton
                            className={cssName(ElixirLotteryElixirs, [
                                ["elixirReserve", "controls", "combatOnly"],
                                ...isCombatOnly ? [["elixirReserve", "controls", "combatOnly", "active"]] : [],
                            ])}
                            text={isCombatOnly ? "Show All" : "Show Combat Only"}
                            onClick={() => {
                                props.setElixirPoolOptions({
                                    ...props.elixirPoolOptions,
                                    isCombatOnly: !isCombatOnly,
                                });
                            }}
                        />
                        <Button
                            className={cssName(ElixirLotteryElixirs, [
                                ["elixirReserve", "controls", "elixirType"],
                                ["elixirReserve", "controls", "elixirType", elixirType],
                            ])}
                            onClick={() => {
                                props.setElixirPoolOptions({
                                    ...props.elixirPoolOptions,
                                    elixirType: elixirType === "blue" ? "green" : "blue",
                                });
                            }}
                        />
                    </div>
                    <div className={cssName(ElixirLotteryElixirs, [
                        ["elixirReserve", "list"],
                    ])}>
                        {
                            sortElixirs(Object.values(ElixirRecord).filter((elixir) => {
                                return !selectedElixirs.includes(elixir)
                                        && (!isCombatOnly || elixir.forCombat);
                            })).map((elixir) => {
                                return (
                                    <ElixirLotteryElixirsElixirEditingField
                                        key={elixir.key}
                                        elixir={elixir}
                                        elixirType={elixirType}
                                        isInPool={false}
                                        onEditClick={() => {
                                            setSelectedElixirs([...selectedElixirs, elixir]);
                                        }}
                                    />
                                );
                            })
                        }
                    </div>
                </div>
                : null
            }
        </div>
    );
}
