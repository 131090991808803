import amityBlueIcon from "assets/images/elixirs/amity_blue.png";
import amityGreenIcon from "assets/images/elixirs/amity_green.png";
import armorBlueIcon from "assets/images/elixirs/armor_blue.png";
import armorGreenIcon from "assets/images/elixirs/armor_green.png";
import assassinationBlueIcon from "assets/images/elixirs/assassination_blue.png";
import assassinationGreenIcon from "assets/images/elixirs/assassination_green.png";
import brawnBlueIcon from "assets/images/elixirs/brawn_blue.png";
import brawnGreenIcon from "assets/images/elixirs/brawn_green.png";
import carnageBlueIcon from "assets/images/elixirs/carnage_blue.png";
import carnageGreenIcon from "assets/images/elixirs/carnage_green.png";
import concentrationBlueIcon from "assets/images/elixirs/concentration_blue.png";
import concentrationGreenIcon from "assets/images/elixirs/concentration_green.png";
import deathBlueIcon from "assets/images/elixirs/death_blue.png";
import deathGreenIcon from "assets/images/elixirs/death_green.png";
import defenseBlueIcon from "assets/images/elixirs/defense_blue.png";
import defenseGreenIcon from "assets/images/elixirs/defense_green.png";
import demihuman_huntBlueIcon from "assets/images/elixirs/demihuman_hunt_blue.png";
import demihuman_huntGreenIcon from "assets/images/elixirs/demihuman_hunt_green.png";
import destructionBlueIcon from "assets/images/elixirs/destruction_blue.png";
import destructionGreenIcon from "assets/images/elixirs/destruction_green.png";
import detectionBlueIcon from "assets/images/elixirs/detection_blue.png";
import detectionGreenIcon from "assets/images/elixirs/detection_green.png";
import drainingBlueIcon from "assets/images/elixirs/draining_blue.png";
import drainingGreenIcon from "assets/images/elixirs/draining_green.png";
import enduranceBlueIcon from "assets/images/elixirs/endurance_blue.png";
import enduranceGreenIcon from "assets/images/elixirs/endurance_green.png";
import energyBlueIcon from "assets/images/elixirs/energy_blue.png";
import energyGreenIcon from "assets/images/elixirs/energy_green.png";
import expBlueIcon from "assets/images/elixirs/exp_blue.png";
import expGreenIcon from "assets/images/elixirs/exp_green.png";
import fisherBlueIcon from "assets/images/elixirs/fisher_blue.png";
import fisherGreenIcon from "assets/images/elixirs/fisher_green.png";
import frenzyBlueIcon from "assets/images/elixirs/frenzy_blue.png";
import frenzyGreenIcon from "assets/images/elixirs/frenzy_green.png";
import furyBlueIcon from "assets/images/elixirs/fury_blue.png";
import furyGreenIcon from "assets/images/elixirs/fury_green.png";
import golden_handBlueIcon from "assets/images/elixirs/golden_hand_blue.png";
import golden_handGreenIcon from "assets/images/elixirs/golden_hand_green.png";
import griffonBlueIcon from "assets/images/elixirs/griffon_blue.png";
import griffonGreenIcon from "assets/images/elixirs/griffon_green.png";
import grim_reaperBlueIcon from "assets/images/elixirs/grim_reaper_blue.png";
import grim_reaperGreenIcon from "assets/images/elixirs/grim_reaper_green.png";
import helixBlueIcon from "assets/images/elixirs/helix_blue.png";
import helixGreenIcon from "assets/images/elixirs/helix_green.png";
import human_huntBlueIcon from "assets/images/elixirs/human_hunt_blue.png";
import human_huntGreenIcon from "assets/images/elixirs/human_hunt_green.png";
import laborBlueIcon from "assets/images/elixirs/labor_blue.png";
import laborGreenIcon from "assets/images/elixirs/labor_green.png";
import lifeBlueIcon from "assets/images/elixirs/life_blue.png";
import lifeGreenIcon from "assets/images/elixirs/life_green.png";
import looneyBlueIcon from "assets/images/elixirs/looney_blue.png";
import looneyGreenIcon from "assets/images/elixirs/looney_green.png";
import masteryBlueIcon from "assets/images/elixirs/mastery_blue.png";
import masteryGreenIcon from "assets/images/elixirs/mastery_green.png";
import mentalityBlueIcon from "assets/images/elixirs/mentality_blue.png";
import mentalityGreenIcon from "assets/images/elixirs/mentality_green.png";
import perforationBlueIcon from "assets/images/elixirs/perforation_blue.png";
import perforationGreenIcon from "assets/images/elixirs/perforation_green.png";
import persistenceBlueIcon from "assets/images/elixirs/persistence_blue.png";
import persistenceGreenIcon from "assets/images/elixirs/persistence_green.png";
import resistanceBlueIcon from "assets/images/elixirs/resistance_blue.png";
import resistanceGreenIcon from "assets/images/elixirs/resistance_green.png";
import resurrectionBlueIcon from "assets/images/elixirs/resurrection_blue.png";
import resurrectionGreenIcon from "assets/images/elixirs/resurrection_green.png";
import sealBlueIcon from "assets/images/elixirs/seal_blue.png";
import sealGreenIcon from "assets/images/elixirs/seal_green.png";
import shockBlueIcon from "assets/images/elixirs/shock_blue.png";
import shockGreenIcon from "assets/images/elixirs/shock_green.png";
import skillBlueIcon from "assets/images/elixirs/skill_blue.png";
import skillGreenIcon from "assets/images/elixirs/skill_green.png";
import skyBlueIcon from "assets/images/elixirs/sky_blue.png";
import skyGreenIcon from "assets/images/elixirs/sky_green.png";
import spellsBlueIcon from "assets/images/elixirs/spells_blue.png";
import spellsGreenIcon from "assets/images/elixirs/spells_green.png";
import swiftnessBlueIcon from "assets/images/elixirs/swiftness_blue.png";
import swiftnessGreenIcon from "assets/images/elixirs/swiftness_green.png";
import timeBlueIcon from "assets/images/elixirs/time_blue.png";
import timeGreenIcon from "assets/images/elixirs/time_green.png";
import trainingBlueIcon from "assets/images/elixirs/training_blue.png";
import trainingGreenIcon from "assets/images/elixirs/training_green.png";
import weenieBlueIcon from "assets/images/elixirs/weenie_blue.png";
import weenieGreenIcon from "assets/images/elixirs/weenie_green.png";
import willBlueIcon from "assets/images/elixirs/will_blue.png";
import willGreenIcon from "assets/images/elixirs/will_green.png";
import windBlueIcon from "assets/images/elixirs/wind_blue.png";
import windGreenIcon from "assets/images/elixirs/wind_green.png";
import wingsBlueIcon from "assets/images/elixirs/wings_blue.png";
import wingsGreenIcon from "assets/images/elixirs/wings_green.png";
import workerBlueIcon from "assets/images/elixirs/worker_blue.png";
import workerGreenIcon from "assets/images/elixirs/worker_green.png";

const ElixirKeyList = [
    "amity",
    "resurrection",
    "human_hunt",
    "frenzy",
    "golden_hand",
    "draining",
    "demihuman_hunt",
    "perforation",
    "energy",
    "wings",
    "death",
    "wind",
    "swiftness",
    "spells",
    "seal",
    "assassination",
    "detection",
    "concentration",
    "will",
    "fury",
    "resistance",
    "life",
    "mentality",
    "grim_reaper",
    "exp",
    "defense",
    "carnage",
    "sky",
    "endurance",
    "worker",
    "fisher",
    "griffon",
    "time",
    "training",
    "shock",
    "weenie",
    "looney",
    "helix",
    "destruction",
    "persistence",
    "brawn",
    "skill",
    "mastery",
    "labor",
    "armor",
] as const;

export type ElixirKey = typeof ElixirKeyList[number];

export type Elixir = Readonly<{
    key: ElixirKey;
    green: string;
    blue: string;
    forCombat: boolean;
    greenIcon: string;
    blueIcon: string;
}>;

export function sortElixirs(elixirs: ReadonlyArray<Elixir>): ReadonlyArray<Elixir> {
    return elixirs.slice().sort((a, b) => {
        return ElixirKeyList.indexOf(a.key) - ElixirKeyList.indexOf(b.key);
    });
}

export const ElixirRecord: Record<ElixirKey, Elixir> = {
    amity: {
        key: "amity",
        green: "Elixir of Amity",
        blue: "Improved Elixir of Amity",
        forCombat: false,
        greenIcon: amityGreenIcon,
        blueIcon: amityBlueIcon,

    },
    resurrection: {
        key: "resurrection",
        green: "Resurrection Elixir",
        blue: "Strong Resurrection Elixir",
        forCombat: true,
        greenIcon: resurrectionGreenIcon,
        blueIcon: resurrectionBlueIcon,

    },
    human_hunt: {
        key: "human_hunt",
        green: "Elixir of Human Hunt",
        blue: "Elixir of Perfect Human Hunt",
        forCombat: true,
        greenIcon: human_huntGreenIcon,
        blueIcon: human_huntBlueIcon,

    },
    frenzy: {
        key: "frenzy",
        green: "Elixir of Frenzy",
        blue: "Elixir of Endless Frenzy",
        forCombat: true,
        greenIcon: frenzyGreenIcon,
        blueIcon: frenzyBlueIcon,

    },
    golden_hand: {
        key: "golden_hand",
        green: "Golden Hand Elixir",
        blue: "Glorious Golden Hand Elixir",
        forCombat: false,
        greenIcon: golden_handGreenIcon,
        blueIcon: golden_handBlueIcon,

    },
    draining: {
        key: "draining",
        green: "Elixir of Draining",
        blue: "Elixir of Strong Draining",
        forCombat: true,
        greenIcon: drainingGreenIcon,
        blueIcon: drainingBlueIcon,

    },
    demihuman_hunt: {
        key: "demihuman_hunt",
        green: "Elixir of Demihuman Hunt",
        blue: "Fierce Demihuman Hunting Elixir",
        forCombat: true,
        greenIcon: demihuman_huntGreenIcon,
        blueIcon: demihuman_huntBlueIcon,

    },
    perforation: {
        key: "perforation",
        green: "Elixir of Perforation",
        blue: "Elixir of Brutal Perforation",
        forCombat: true,
        greenIcon: perforationGreenIcon,
        blueIcon: perforationBlueIcon,

    },
    energy: {
        key: "energy",
        green: "Elixir of Energy",
        blue: "Surging Energy Elixir",
        forCombat: true,
        greenIcon: energyGreenIcon,
        blueIcon: energyBlueIcon,

    },
    wings: {
        key: "wings",
        green: "Elixir of Wings",
        blue: "Soaring Wings Elixir",
        forCombat: false,
        greenIcon: wingsGreenIcon,
        blueIcon: wingsBlueIcon,

    },
    death: {
        key: "death",
        green: "Elixir of Death",
        blue: "Brutal Death Elixir",
        forCombat: true,
        greenIcon: deathGreenIcon,
        blueIcon: deathBlueIcon,

    },
    wind: {
        key: "wind",
        green: "Elixir of Wind",
        blue: "Elixir of Flowing Wind",
        forCombat: true,
        greenIcon: windGreenIcon,
        blueIcon: windBlueIcon,

    },
    swiftness: {
        key: "swiftness",
        green: "Elixir of Swiftness",
        blue: "Elixir of Intrepid Swiftness",
        forCombat: true,
        greenIcon: swiftnessGreenIcon,
        blueIcon: swiftnessBlueIcon,

    },
    spells: {
        key: "spells",
        green: "Elixir of Spells",
        blue: "Elixir of Agile Spells",
        forCombat: true,
        greenIcon: spellsGreenIcon,
        blueIcon: spellsBlueIcon,

    },
    seal: {
        key: "seal",
        green: "Elixir of Seal",
        blue: "Agile Seal Elixir",
        forCombat: false,
        greenIcon: sealGreenIcon,
        blueIcon: sealBlueIcon,

    },
    assassination: {
        key: "assassination",
        green: "Elixir of Assassination",
        blue: "Elixir of Lethal Assassin",
        forCombat: true,
        greenIcon: assassinationGreenIcon,
        blueIcon: assassinationBlueIcon,

    },
    detection: {
        key: "detection",
        green: "Elixir of Detection",
        blue: "Elixir of Sharp Detection",
        forCombat: true,
        greenIcon: detectionGreenIcon,
        blueIcon: detectionBlueIcon,

    },
    concentration: {
        key: "concentration",
        green: "Elixir of Concentration",
        blue: "Elixir of Advanced Concentration",
        forCombat: true,
        greenIcon: concentrationGreenIcon,
        blueIcon: concentrationBlueIcon,

    },
    will: {
        key: "will",
        green: "Elixir of Will",
        blue: "Elixir of Remarkable Will",
        forCombat: true,
        greenIcon: willGreenIcon,
        blueIcon: willBlueIcon,

    },
    fury: {
        key: "fury",
        green: "Elixir of Fury",
        blue: "Elixir of Endless Fury",
        forCombat: true,
        greenIcon: furyGreenIcon,
        blueIcon: furyBlueIcon,

    },
    resistance: {
        key: "resistance",
        green: "Elixir of Resistance",
        blue: "Elixir of Sharp Resistance",
        forCombat: true,
        greenIcon: resistanceGreenIcon,
        blueIcon: resistanceBlueIcon,

    },
    life: {
        key: "life",
        green: "Elixir of Life",
        blue: "Elixir of Strong Life",
        forCombat: true,
        greenIcon: lifeGreenIcon,
        blueIcon: lifeBlueIcon,

    },
    mentality: {
        key: "mentality",
        green: "Elixir of Mentality",
        blue: "Elixir of Clear Mentality",
        forCombat: true,
        greenIcon: mentalityGreenIcon,
        blueIcon: mentalityBlueIcon,

    },
    grim_reaper: {
        key: "grim_reaper",
        green: "Grim Reaper's Elixir",
        blue: "Grim Soul Reaper's Elixir",
        forCombat: true,
        greenIcon: grim_reaperGreenIcon,
        blueIcon: grim_reaperBlueIcon,

    },
    exp: {
        key: "exp",
        green: "EXP Elixir",
        blue: "Splendid EXP Elixir",
        forCombat: false,
        greenIcon: expGreenIcon,
        blueIcon: expBlueIcon,

    },
    defense: {
        key: "defense",
        green: "Defense Elixir",
        blue: "Elixir of Steel Defense",
        forCombat: true,
        greenIcon: defenseGreenIcon,
        blueIcon: defenseBlueIcon,

    },
    carnage: {
        key: "carnage",
        green: "Elixir of Carnage",
        blue: "Elixir of Brutal Carnage",
        forCombat: true,
        greenIcon: carnageGreenIcon,
        blueIcon: carnageBlueIcon,

    },
    sky: {
        key: "sky",
        green: "Elixir of Sky",
        blue: "Merciless Sky Elixir",
        forCombat: true,
        greenIcon: skyGreenIcon,
        blueIcon: skyBlueIcon,

    },
    endurance: {
        key: "endurance",
        green: "Elixir of Endurance",
        blue: "Elixir of Overwhelming Endurance",
        forCombat: true,
        greenIcon: enduranceGreenIcon,
        blueIcon: enduranceBlueIcon,

    },
    worker: {
        key: "worker",
        green: "Worker's Elixir",
        blue: "Skilled Worker's Elixir",
        forCombat: false,
        greenIcon: workerGreenIcon,
        blueIcon: workerBlueIcon,

    },
    fisher: {
        key: "fisher",
        green: "Fisher's Elixir",
        blue: "Skilled Fisher's Elixir",
        forCombat: false,
        greenIcon: fisherGreenIcon,
        blueIcon: fisherBlueIcon,

    },
    griffon: {
        key: "griffon",
        green: "Griffon's Elixir",
        blue: "Strong Griffon's Elixir",
        forCombat: true,
        greenIcon: griffonGreenIcon,
        blueIcon: griffonBlueIcon,

    },
    time: {
        key: "time",
        green: "Elixir of Time",
        blue: "Elixir of Flowing Time",
        forCombat: false,
        greenIcon: timeGreenIcon,
        blueIcon: timeBlueIcon,

    },
    training: {
        key: "training",
        green: "Elixir of Training",
        blue: "Elixir of Expert Training",
        forCombat: false,
        greenIcon: trainingGreenIcon,
        blueIcon: trainingBlueIcon,

    },
    shock: {
        key: "shock",
        green: "Elixir of Shock",
        blue: "Elixir of Strong Shock",
        forCombat: true,
        greenIcon: shockGreenIcon,
        blueIcon: shockBlueIcon,

    },
    weenie: {
        key: "weenie",
        green: "Weenie Elixir",
        blue: "Surging Weenie Elixir",
        forCombat: false,
        greenIcon: weenieGreenIcon,
        blueIcon: weenieBlueIcon,

    },
    looney: {
        key: "looney",
        green: "Looney Elixir",
        blue: "Reliable Looney Elixir",
        forCombat: false,
        greenIcon: looneyGreenIcon,
        blueIcon: looneyBlueIcon,

    },
    helix: {
        key: "helix",
        green: "Helix Elixir",
        blue: "Splendid Helix Elixir",
        forCombat: true,
        greenIcon: helixGreenIcon,
        blueIcon: helixBlueIcon,

    },
    destruction: {
        key: "destruction",
        green: "Elixir of Destruction",
        blue: "Elixir of Lethal Destruction",
        forCombat: true,
        greenIcon: destructionGreenIcon,
        blueIcon: destructionBlueIcon,

    },
    persistence: {
        key: "persistence",
        green: "Elixir of Persistence",
        blue: "Elixir of Endless Persistence",
        forCombat: false,
        greenIcon: persistenceGreenIcon,
        blueIcon: persistenceBlueIcon,

    },
    brawn: {
        key: "brawn",
        green: "Elixir of Brawn",
        blue: "Elixir of Raw Brawn",
        forCombat: false,
        greenIcon: brawnGreenIcon,
        blueIcon: brawnBlueIcon,

    },
    skill: {
        key: "skill",
        green: "Elixir of Skill",
        blue: "Elixir of Infinite Skill",
        forCombat: false,
        greenIcon: skillGreenIcon,
        blueIcon: skillBlueIcon,

    },
    mastery: {
        key: "mastery",
        green: "Elixir of Mastery",
        blue: "Elixir of Improved Mastery",
        forCombat: false,
        greenIcon: masteryGreenIcon,
        blueIcon: masteryBlueIcon,

    },
    labor: {
        key: "labor",
        green: "Elixir of Labor",
        blue: "Elixir of Rough Labor",
        forCombat: false,
        greenIcon: laborGreenIcon,
        blueIcon: laborBlueIcon,

    },
    armor: {
        key: "armor",
        green: "Elixir of Armor",
        blue: "Elixir of Corrupted Armor",
        forCombat: true,
        greenIcon: armorGreenIcon,
        blueIcon: armorBlueIcon,

    },
};
