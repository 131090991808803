import { cssName } from "components";
import { TextButton } from "components/button";
import { useEffect, useRef, useState } from "react";
import "./index.css";

const UPDATE_INTERVAL: number = 100; // 100 milliseconds or 0.1 seconds
const COUNTER_MAX_VALUE: number = 99 * 1000; // 99 seconds
const USER_INPUT_DELTA: number = 1 * 1000;  // When user clicks on the increment/decrement button
const ALERT_LIMIT: number = 5 * 1000; // Value at which it should start alerting user
const DEFAULT_RESET_VALUE = 40 * 1000; // Default value of 40 seconds

SycrakeaBossToolCountdown.displayName = "SycrakeaBossToolCountdown";
export function SycrakeaBossToolCountdown(): React.ReactElement {
    const [isRunning, setRunning] = useState<boolean>(false);
    const [counter, setCounter] = useState<number>(DEFAULT_RESET_VALUE);
    const [autoRestart, setAutoRestart] = useState<boolean>(false);
    const autoRestartRef = useRef<boolean>(false)

    const resetValue = useRef<number>(DEFAULT_RESET_VALUE);
    const lastUpdatedTime = useRef<number>(Date.now());
    const intervalId = useRef<NodeJS.Timer>();

    useEffect(() => {
        if (isRunning) {
            intervalId.current = setInterval(() => {
                const now: number = Date.now();
                const timeDelta: number = now - lastUpdatedTime.current;
                lastUpdatedTime.current = now;
                setCounter(prevCounter => {
                    const nextCounter: number = prevCounter - timeDelta;
                    if (nextCounter < 0) {
                        if (autoRestartRef.current) {
                            return nextCounter + resetValue.current;
                        } else {
                            return 0;
                        }
                    }
                    return nextCounter;
                });
            }, UPDATE_INTERVAL);
        } else {
            clearInterval(intervalId.current)
        }

        return () => clearInterval(intervalId.current);
    }, [isRunning]);

    const resetCounter = (): void => {
        setRunning(false);
        setCounter(resetValue.current);
    };

    const restartCounter = (): void => {
        setCounter(resetValue.current);
        lastUpdatedTime.current = Date.now();
        if (!isRunning) {
            setRunning(true);
        }
    };

    const userDecrementCounter = (): void => {
        if (!isRunning) {
            const newResetValue = Math.max(resetValue.current - USER_INPUT_DELTA, 0);
            resetValue.current = newResetValue;
            setCounter(newResetValue);
        } else {
            const newCounterValue = Math.max(counter - USER_INPUT_DELTA, 0);
            setCounter(newCounterValue);
        }
    };

    const userIncrementCounter = (): void => {
        if (!isRunning) {
            const newResetValue = Math.min(resetValue.current + USER_INPUT_DELTA, COUNTER_MAX_VALUE);
            resetValue.current = newResetValue;
            setCounter(newResetValue);
        } else {
            const newCounterValue = Math.min(counter + USER_INPUT_DELTA, COUNTER_MAX_VALUE);
            setCounter(newCounterValue);
        }
    };

    const toggleAutoRestart = (): void => {
        const newAutoRestartState = !autoRestart;
        setAutoRestart(newAutoRestartState);
        autoRestartRef.current = newAutoRestartState;
    };

    return (
        <div className={cssName(SycrakeaBossToolCountdown)}>
            <SycrakeaBossToolCountdownDisplay
                value={counter}
                alertLimit={ALERT_LIMIT}
            />
            <div className={cssName(SycrakeaBossToolCountdown, [
                ["controls"],
            ])}>
                <TextButton
                    className={cssName(SycrakeaBossToolCountdown, [
                        ["controls", "button"],
                        ["controls", "button", "decrement"],
                    ])}
                    text={"-"}
                    onClick={userDecrementCounter}
                />
                <TextButton
                    className={cssName(SycrakeaBossToolCountdown, [
                        ["controls", "button"],
                        ["controls", "button", "increment"],
                    ])}
                    text={"+"}
                    onClick={userIncrementCounter}
                />
                <TextButton
                    className={cssName(SycrakeaBossToolCountdown, [
                        ["controls", "button"],
                        ["controls", "button", "reset"],
                    ])}
                    text={"(re)set"}
                    onClick={resetCounter}
                />
                <TextButton
                    className={cssName(SycrakeaBossToolCountdown, [
                        ["controls", "button"],
                        ["controls", "button", "restart"],
                    ])}
                    text={"(re)start"}
                    onClick={restartCounter}
                />
                <TextButton
                    className={cssName(SycrakeaBossToolCountdown, [
                        ["controls", "button"],
                        ["controls", "button", "autorestart"],
                        ...autoRestart ? [["controls", "button", "autorestart", "active"]] : [],
                    ])}
                    text={"auto restart"}
                    onClick={toggleAutoRestart}
                    disabled={isRunning}
                />
            </div>
        </div>
    );
}

SycrakeaBossToolCountdownDisplay.displayName = "SycrakeaBossToolCountdownDisplay";
function SycrakeaBossToolCountdownDisplay(props: Readonly<{
    value: number;
    alertLimit: number;
}>): React.ReactElement {
    const shouldAlert: boolean = props.value <= props.alertLimit;
    return (
        <span className={cssName(SycrakeaBossToolCountdownDisplay, [
            [],
            ...shouldAlert ? [["alert"]] : [],
        ])}>
            {String(Math.ceil(props.value / 1000)).padStart(2, "0")}
        </span>
    );
}
