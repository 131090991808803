export function setDocumentTitle(title: string): void {
    document.title = `${title} - SnQ`;
}

const cookiePaths = {
    "el-elixirs": "/bdo/elixir-lottery",
    "el-players": "/bdo/elixir-lottery",
    "el-elixirPoolOptions": "/bdo/elixir-lottery",
};

type Cookie = keyof typeof cookiePaths;

export function setDocumentCookie(name: Cookie, value: any): void {
    const encodedValue = encodeURIComponent(JSON.stringify(value));
    const maxAge = 60*60*24*60; // 2 months
    const path = cookiePaths[name as keyof typeof cookiePaths];
    document.cookie = `${name}=${encodedValue}; max-age=${maxAge}; path=${path}; samesite=strict; secure`;
}

export function getDocumentCookie(name: Cookie): any {
    const value = document.cookie.split(";").find((item) => item.trim().startsWith(`${name}=`))?.split("=")[1];
    if (value) {
        return JSON.parse(decodeURIComponent(value));
    } else {
        return null;
    }
}
