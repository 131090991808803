import { DEFAULT_ELIXIRS, DEFAULT_ELIXIR_POOL_OPTIONS, ElixirLotteryElixirs, ElixirPoolOptions } from "bdo/elixir-lottery/elixir";
import { DEFAULT_PLAYERS, ElixirLotteryPlayers, Player, PlayerState } from "bdo/elixir-lottery/player";
import { cssName } from "components";
import { useEffect, useState } from "react";
import { getDocumentCookie, setDocumentCookie, setDocumentTitle } from "util/browser";
import { Elixir, ElixirKey, ElixirRecord } from "util/elixir";
import "./index.css";

function getDefaultPlayerStates(): ReadonlyArray<PlayerState> {
    const savedPlayerListObj: any = getDocumentCookie("el-players");
    if (savedPlayerListObj !== null) {
        const savedPlayerList = savedPlayerListObj as ReadonlyArray<Player>;
        if (savedPlayerList.length === 5) {
            return savedPlayerList.map((player, index): PlayerState => {
                return {
                    key: index,
                    player,
                    elixirs: [],
                };
            });
        }
    }
    return DEFAULT_PLAYERS.map((player, index): PlayerState => {
        return {
            key: index,
            player,
            elixirs: [],
        };
    });
}

export function getDefaultElixirs(): ReadonlyArray<Elixir> {
    const savedElixirListObj: any = getDocumentCookie("el-elixirs");
    if (savedElixirListObj !== null) {
        const savedElixirList = savedElixirListObj as ReadonlyArray<ElixirKey>;
        if (savedElixirList.length > 0) {
            return savedElixirList.map((savedKey) => {
                return ElixirRecord[savedKey];
            });
        }
    }
    return DEFAULT_ELIXIRS;
}

export function getSavedElixirPoolOptions(): ElixirPoolOptions {
    const savedElixirPoolOptionsObj: any = getDocumentCookie("el-elixirPoolOptions");
    if (savedElixirPoolOptionsObj !== null) {
        return savedElixirPoolOptionsObj as ElixirPoolOptions;
    }
    return DEFAULT_ELIXIR_POOL_OPTIONS;
}

ElixirLotteryPage.displayName = "ElixirLotteryPage";
export function ElixirLotteryPage(): React.ReactElement {
    useEffect(() => {
        setDocumentTitle("Elixir Lottery");
    }, []);

    return (
        <div className={cssName(ElixirLotteryPage)}>
            {"Use Harmony Draughts"}
        </div>
    );
    /*
    return (
        <div className={cssName(ElixirLotteryPage)}>
            <ElixirLottery />
        </div>
    );
    */
}

ElixirLottery.displayName = "ElixirLottery";
function ElixirLottery(): React.ReactElement {
    const [playerStates, setPlayerStates] = useState<ReadonlyArray<PlayerState>>(getDefaultPlayerStates());
    const [elixirPool, setElixirPool] = useState<ReadonlyArray<Elixir>>(getDefaultElixirs());
    const [elixirPoolOptions, setElixirPoolOptions] = useState<ElixirPoolOptions>(getSavedElixirPoolOptions());

    useEffect(() => {
        const newPlayerList: ReadonlyArray<Player> = playerStates.map((playerState) => playerState.player);
        setDocumentCookie("el-players", newPlayerList);
    }, [playerStates]);

    useEffect(() => {
        const newElixirPool: ReadonlyArray<ElixirKey> = elixirPool.map((elixir) => elixir.key);
        setDocumentCookie("el-elixirs", newElixirPool);
    }, [elixirPool]);

    useEffect(() => {
        setDocumentCookie("el-elixirPoolOptions", elixirPoolOptions);
    }, [elixirPoolOptions]);

    return (
        <div className={cssName(ElixirLottery)}>
            <div className={cssName(ElixirLottery, [
                ["players"],
            ])}>
                <ElixirLotteryPlayers
                    playerStates={playerStates}
                    setPlayerStates={setPlayerStates}
                    elixirPool={elixirPool}
                    elixirType={elixirPoolOptions.elixirType}
                />
            </div>
            <div className={cssName(ElixirLottery, [
                ["elixirs"],
            ])}>
                <ElixirLotteryElixirs
                    elixirPool={elixirPool}
                    setElixirPool={setElixirPool}
                    elixirPoolOptions={elixirPoolOptions}
                    setElixirPoolOptions={setElixirPoolOptions}
                />
            </div>
        </div>
    );
}
