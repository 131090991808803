import { cssName } from "components";
import { Button, TextButton } from "components/button";
import { useEffect, useRef, useState } from "react";
import { assertNever } from "util/typecheck";
import "./index.css";

const MAX_COLOR_COUNT: number = 9;
const COLOR_TYPES = ["red", "yellow", "white", "blue"] as const;
type SycrakeaBossToolColorsColorType = (typeof COLOR_TYPES)[number];

SycrakeaBossToolColors.displayName = "SycrakeaBossToolColors";
export function SycrakeaBossToolColors(): React.ReactElement {
    const [red, setRed] = useState<number>(0);
    const [yellow, setYellow] = useState<number>(0);
    const [white, setWhite] = useState<number>(0);
    const [blue, setBlue] = useState<number>(0);

    const [highestCountColor, setHighestCountColor] = useState<SycrakeaBossToolColorsColorType | null> (null);

    const undoStack = useRef<Array<SycrakeaBossToolColorsColorType>>([]);

    useEffect(() => {
        let highestCount: number = red;
        let highestColor: SycrakeaBossToolColorsColorType | null = "red";

        if (yellow > highestCount) {
            highestCount = yellow;
            highestColor = "yellow";
        } else if (yellow === highestCount) {
            highestColor = null;
        }

        if (white > highestCount) {
            highestCount = white;
            highestColor = "white";
        } else if (white === highestCount) {
            highestColor = null;
        }

        if (blue > highestCount) {
            highestCount = blue;
            highestColor = "blue";
        } else if (blue === highestCount) {
            highestColor = null;
        }

        setHighestCountColor(highestColor);
    }, [red, yellow, white, blue]);

    return (
        <div className={cssName(SycrakeaBossToolColors)}>
            <SycrakeaBossToolColorsColorCounter
                colorType={"red"}
                count={red}
                onClick={() => {
                    const updatedValue: number = red + 1;
                    if (updatedValue <= MAX_COLOR_COUNT) {
                        undoStack.current.push("red");
                        setRed(updatedValue);
                    }
                }}
                isHighlighted={highestCountColor === "red"}
            />
            <SycrakeaBossToolColorsColorCounter
                colorType={"yellow"}
                count={yellow}
                onClick={() => {
                    const updatedValue: number = yellow + 1;
                    if (updatedValue <= MAX_COLOR_COUNT) {
                        undoStack.current.push("yellow");
                        setYellow(updatedValue);
                    }
                }}
                isHighlighted={highestCountColor === "yellow"}
            />
            <SycrakeaBossToolColorsColorCounter
                colorType={"white"}
                count={white}
                onClick={() => {
                    const updatedValue: number = white + 1;
                    if (updatedValue <= MAX_COLOR_COUNT) {
                        undoStack.current.push("white");
                        setWhite(updatedValue);
                    }
                }}
                isHighlighted={highestCountColor === "white"}
            />
            <SycrakeaBossToolColorsColorCounter
                colorType={"blue"}
                count={blue}
                onClick={() => {
                    const updatedValue: number = blue + 1;
                    if (updatedValue <= MAX_COLOR_COUNT) {
                        undoStack.current.push("blue");
                        setBlue(updatedValue);
                    }
                }}
                isHighlighted={highestCountColor === "blue"}
            />
            <div className={cssName(SycrakeaBossToolColors, [
                ["controls"],
            ])}>
                <TextButton
                    className={cssName(SycrakeaBossToolColors, [
                        ["controls", "button"],
                    ])}
                    text={"reset"}
                    onClick={() => {
                        undoStack.current.length = 0;
                        setRed(0);
                        setYellow(0);
                        setWhite(0);
                        setBlue(0);
                    }}
                />
                <TextButton
                    className={cssName(SycrakeaBossToolColors, [
                        ["controls", "button"],
                    ])}
                    text={"undo"}
                    onClick={() => {
                        const lastModifiedColor = undoStack.current.pop();
                        if (lastModifiedColor) {
                            switch (lastModifiedColor) {
                                case "red":
                                    setRed(red - 1);
                                    return;
                                case "yellow":
                                    setYellow(yellow - 1);
                                    return;
                                case "white":
                                    setWhite(white - 1);
                                    return;
                                case "blue":
                                    setBlue(blue - 1);
                                    return;
                                default:
                                    assertNever(lastModifiedColor);
                            }
                        }
                    }}
                    disabled={undoStack.current.length === 0}
                />
            </div>
        </div>
    );
}

SycrakeaBossToolColorsColorCounter.displayName = "SycrakeaBossToolColorsColorCounter";
function SycrakeaBossToolColorsColorCounter(props: Readonly<{
    colorType: SycrakeaBossToolColorsColorType;
    count: number;
    onClick: () => void;
    isHighlighted: boolean;
}>): React.ReactElement {
    return (
        <Button
            className={cssName(SycrakeaBossToolColorsColorCounter, [
                [],
                [props.colorType],
            ])}
            onClick={props.onClick}
        >
            <span className={cssName(SycrakeaBossToolColorsColorCounter, [
                ["value"],
                ...props.isHighlighted ? [["value", "highlight"]] : [],
            ])}>
                {props.count}
            </span>
        </Button>
    );
}
