import { cssName } from "components";
import "./index.css";

type ButtonProps = Readonly<{
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
}>;

Button.displayName = "Button";
export function Button(props: React.PropsWithChildren<ButtonProps>): React.ReactElement {
    return (
        <button
            type="button"
            className={`${cssName(Button)} ${props.className ?? ""}`.trim()}
            onClick={props.onClick}
            disabled={props.disabled ?? false}
        >
            {props.children}
        </button>
    );
}

TextButton.displayName = "TextButton";
export function TextButton(props: ButtonProps & Readonly<{
    text: string;
}>): React.ReactElement {
    return (
        <Button {...props}>
            <span className={cssName(TextButton, [
                ["text"],
            ])}>
                {props.text}
            </span>
        </Button>
    );
}
