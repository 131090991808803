import { Navigate, Route, Routes } from "react-router-dom";

export type RouteInfo = Readonly<{
    path: string;
    element: React.ReactElement;
}>;

export function IndexedRoutes(props: Readonly<{
    indexElement: React.ReactElement,
    routes: ReadonlyArray<RouteInfo>,
}>): React.ReactElement {
    return (
        <Routes>
            <Route index element={props.indexElement} />
            {
                props.routes.map((route, index) => {
                    return <Route key={index} path={route.path} element={route.element} />;
                })
            }
            <Route path="*" element={<Navigate to=".." replace />} />
        </Routes>
    );
}
