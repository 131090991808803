interface ReactFunctionalComponentWithDisplayName {
    (...args: any): React.ReactElement;
    displayName: string;
}

export function cssName(component: ReactFunctionalComponentWithDisplayName, classLists: ReadonlyArray<ReadonlyArray<string>> = [[""]]): string {
    return classLists.map((classList) => {
        return [component.displayName, ...classList].filter((name) => name).join("-");
    }).join(" ");
}
