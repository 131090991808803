
import { ElixirLotteryElixirsElixirField, ElixirType } from "bdo/elixir-lottery/elixir";
import { cssName } from "components";
import { TextButton } from "components/button";
import { useState } from "react";
import { shuffle } from "util/array";
import { Elixir } from "util/elixir";
import "./index.css";

const ROLL_DELAY_ms = 66;

export type Player = Readonly<{
    name: string;
    isEnabled: boolean;
}>;

export type PlayerState = Readonly<{
    key: number;
    player: Player;
    elixirs: ReadonlyArray<Elixir>;
}>;

export const PLAYER_COUNT: number = 5;

export const DEFAULT_PLAYERS: ReadonlyArray<Player> = [
    {
        name: "Group 1",
        isEnabled: true,
    },
    {
        name: "Group 2",
        isEnabled: true,
    },
    {
        name: "Group 3",
        isEnabled: true,
    },
    {
        name: "Group 4",
        isEnabled: true,
    },
    {
        name: "Group 5",
        isEnabled: true,
    },
];

ElixirLotteryPlayerField.displayName = "ElixirLotteryPlayerField";
function ElixirLotteryPlayerField(props: Readonly<{
    playerState: PlayerState;
    elixirType: ElixirType;
    setName: (name: string) => void;
    setEnabled: (isEnabled: boolean) => void;
}>): React.ReactElement {
    const {
        player,
        elixirs,
    } = props.playerState;
    const isEnabled = player.isEnabled;

    return (
        <div className={cssName(ElixirLotteryPlayerField)}>
            <div
                onClick={() => props.setEnabled(!isEnabled)}
                className={cssName(ElixirLotteryPlayerField, [
                    ["toggle"],
                    ["toggle", isEnabled ? "enabled" : "disabled"],
                ])}
            ></div>
            <input
                type="text"
                value={player.name}
                onChange={(e) => props.setName(e.target.value)}
                className={cssName(ElixirLotteryPlayerField, [
                    ["name"],
                ])}
                disabled={!isEnabled}
            />
            <div className={cssName(ElixirLotteryPlayerField, [
                ["elixirs"],
                ...player.isEnabled ? [] : [["elixirs", "disabled"]],
            ])}>
                {
                    elixirs.map((elixir, index) => {
                        return (
                            <ElixirLotteryElixirsElixirField
                                key={index}
                                elixir={elixir}
                                elixirType={props.elixirType}
                            />
                        );
                    })
                }
            </div>
        </div>
    );
}

ElixirLotteryPlayers.displayName = "ElixirLotteryPlayers";
export function ElixirLotteryPlayers(props: Readonly<{
    playerStates: ReadonlyArray<PlayerState>;
    setPlayerStates: (playerStates: ReadonlyArray<PlayerState>) => void;
    elixirPool: ReadonlyArray<Elixir>;
    elixirType: ElixirType;
}>): React.ReactElement {
    const {
        playerStates,
        setPlayerStates,
        elixirPool,
        elixirType,
    } = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);

    return (
        <div className={cssName(ElixirLotteryPlayers)}>
            <div className={cssName(ElixirLotteryPlayers, [
                ["controls"],
            ])}>
                <TextButton
                    text={"Roll"}
                    onClick={() => {
                        if (props.playerStates.every((playerState) => !playerState.player.isEnabled)) {
                            return;
                        }

                        setIsLoading(true);
                        new Promise<void>(async (resolve) => {
                            let resultPlayerStates: Array<PlayerState> = playerStates.map((state) => {
                                return {
                                    ...state,
                                    elixirs: [],
                                };
                            });

                            let playerPool: Array<PlayerState> = [];
                            let shuffledElixirs: Array<Elixir> = shuffle(elixirPool);
                            while (shuffledElixirs.length > 0) {
                                if (playerPool.length === 0) {
                                    playerPool = [...playerStates];
                                }

                                const playerIndex = Math.floor(Math.random() * playerPool.length);
                                const [selectedPlayerState] = playerPool.splice(playerIndex, 1);
                                if (selectedPlayerState.player.isEnabled) {
                                    const poppedElixir: Elixir | undefined = shuffledElixirs.pop();
                                    if (poppedElixir) {
                                        await new Promise(resolveDelay => setTimeout(resolveDelay, ROLL_DELAY_ms));
                                        resultPlayerStates = resultPlayerStates.map((state) => {
                                            if (state.key === selectedPlayerState.key) {
                                                return {
                                                    ...state,
                                                    elixirs: [...state.elixirs, poppedElixir],
                                                };
                                            }
                                            return state;
                                        });
                                        setPlayerStates(resultPlayerStates);
                                    }
                                }
                            }
                            resolve();
                        }).finally(() => {
                            setIsLoading(false);
                        });
                    }}
                    disabled={isLoading || props.playerStates.every((playerState) => !playerState.player.isEnabled)}
                />
                <TextButton
                    text={"Copy to Clipboard"}
                    onClick={() => {
                        const resultString = playerStates.filter((playerState) => playerState.elixirs.length > 0).map((playerState) => {
                            return [playerState.player.name, ...playerState.elixirs.map((elixir) => {
                                return `-- ${elixirType === "blue" ? elixir.blue : elixir.green}`;
                            })].join("\n");
                        }).join("\n");

                        setIsLoading(true);

                        navigator.clipboard.writeText(resultString).finally(() => {
                            setIsLoading(false);
                        });
                    }}
                    disabled={isLoading}
                />
            </div>
            <div className={cssName(ElixirLotteryPlayers, [
                ["players"],
            ])}>
                {
                    playerStates.map((playerState, index) => {
                        const setName = (name: string) => {
                            setPlayerStates(playerStates.map((oldPlayerState, oldPlayerIndex) => {
                                if (oldPlayerIndex === index) {
                                    return {
                                        ...oldPlayerState,
                                        player: {
                                            ...oldPlayerState.player,
                                            name,
                                        },
                                    };
                                }
                                return oldPlayerState;
                            }));
                        };

                        const setEnabled = (isEnabled: boolean) => {
                            setPlayerStates(playerStates.map((oldPlayerState, oldPlayerIndex) => {
                                if (oldPlayerIndex === index) {
                                    return {
                                        ...oldPlayerState,
                                        player: {
                                            ...oldPlayerState.player,
                                            isEnabled,
                                        },
                                    };
                                }
                                return oldPlayerState;
                            }));
                        };

                        return (
                            <ElixirLotteryPlayerField
                                key={index}
                                playerState={playerState}
                                elixirType={elixirType}
                                setName={setName}
                                setEnabled={setEnabled}
                            />
                        );
                    })
                }
            </div>
        </div>
    );
}
