import { MaintenancePage } from "app";
import { AtoraxxionPage } from "bdo/atoraxxion";
import { ElixirLotteryPage } from "bdo/elixir-lottery";
import { IndexedRoutes } from "util/route";

BDOPage.displayName = "BDOPage";
export function BDOPage(): React.ReactElement {
    return (
        <IndexedRoutes
            indexElement={<MaintenancePage />}
            routes={[
                {
                    path: "atoraxxion/*",
                    element: <AtoraxxionPage />,
                },
                {
                    path: "elixir-lottery",
                    element: <ElixirLotteryPage />,
                },
            ]}
        />
    );
}
